import { template as template_09c59750db7c483c8307f2ff313c149e } from "@ember/template-compiler";
import PixBlock from '@1024pix/pix-ui/components/pix-block';
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixCode from '@1024pix/pix-ui/components/pix-code';
import PixNotificationAlert from '@1024pix/pix-ui/components/pix-notification-alert';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import LanguageSwitcher from './language-switcher';
export default class CampaignCodeForm extends Component {
    @service
    intl;
    @service
    currentUser;
    @service
    currentDomain;
    @service
    session;
    @tracked
    campaignCode = null;
    @tracked
    apiErrorMessage = null;
    @tracked
    validationErrorMessage = null;
    @tracked
    validationStatus = 'default';
    get firstTitle() {
        return this.args.isUserAuthenticatedByPix && !this.currentUser.user.isAnonymous ? this.intl.t('pages.fill-in-campaign-code.first-title-connected', {
            firstName: this.currentUser.user.firstName
        }) : this.intl.t('pages.fill-in-campaign-code.first-title-not-connected');
    }
    get showWarningMessage() {
        return this.args.isUserAuthenticatedByPix && !this.currentUser.user.isAnonymous;
    }
    get isInternationalDomain() {
        return !this.currentDomain.isFranceDomain;
    }
    get isUserNotAuthenticated() {
        return !this.args.isUserAuthenticatedByPix && !this.args.isUserAuthenticatedByGAR;
    }
    get canDisplayLanguageSwitcher() {
        return this.isInternationalDomain && this.isUserNotAuthenticated;
    }
    get warningMessage() {
        return this.intl.t('pages.fill-in-campaign-code.warning-message', {
            firstName: this.currentUser.user.firstName,
            lastName: this.currentUser.user.lastName
        });
    }
    @action
    clearErrorMessage() {
        this.validationStatus = 'default';
        this.validationErrorMessage = null;
        this.args.clearErrors();
    }
    @action
    disconnect() {
        this.session.invalidate();
    }
    @action
    handleCampaignCodeInput(event) {
        this.campaignCode = event.target.value;
    }
    @action
    async startCampaign(event) {
        event.preventDefault();
        this.clearErrorMessage();
        if (!this.campaignCode) {
            this.validationStatus = 'error';
            this.validationErrorMessage = this.intl.t('pages.fill-in-campaign-code.errors.missing-code');
            return;
        }
        const campaignCode = this.campaignCode.toUpperCase();
        this.args.startCampaign(campaignCode);
    }
    static{
        template_09c59750db7c483c8307f2ff313c149e(`
    <PixBlock class="fill-in-campaign-code__container">
      <h1 class="fill-in-campaign-code__title">
        {{this.firstTitle}}
      </h1>
      <p id="campaign-code-description" class="fill-in-campaign-code__instruction">{{t
          "pages.fill-in-campaign-code.description"
        }}</p>

      <form class="fill-in-campaign-code__form" autocomplete="off">
        <PixCode
          @id="campaign-code"
          @length="9"
          @requiredLabel={{t "common.forms.mandatory"}}
          @screenReaderOnly={{true}}
          @value={{this.certificateVerificationCode}}
          @validationStatus={{this.validationStatus}}
          @errorMessage={{this.validationErrorMessage}}
          aria-describedby="campaign-code-description"
          {{on "keyup" this.clearErrorMessage}}
          {{on "input" this.handleCampaignCodeInput}}
        >
          <:label>{{t "pages.fill-in-campaign-code.label"}}</:label>
        </PixCode>

        {{#if @apiErrorMessage}}
          <PixNotificationAlert @type="error" @withIcon={{true}}>
            {{@apiErrorMessage}}
          </PixNotificationAlert>
        {{/if}}

        <PixButton @type="submit" @triggerAction={{this.startCampaign}}>
          {{t "pages.fill-in-campaign-code.start"}}
        </PixButton>
      </form>

      {{#if this.showWarningMessage}}
        <div class="fill-in-campaign-code__warning">
          <span>{{this.warningMessage}}</span>
          <a href="#" class="link" {{on "click" this.disconnect}}>
            {{t "pages.fill-in-campaign-code.warning-message-logout"}}
          </a>
        </div>
      {{/if}}

    </PixBlock>
    {{#if this.canDisplayLanguageSwitcher}}
      <LanguageSwitcher @selectedLanguage={{@selectedLanguage}} @onLanguageChange={{@onLanguageChange}} />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
