import { template as template_6357e0e66ea148bdb7010842bbfe21a2 } from "@ember/template-compiler";
import { element } from 'ember-element-helper';
import ModuleElement from './module-element';
export default class ModulixCustomElement extends ModuleElement {
    static{
        template_6357e0e66ea148bdb7010842bbfe21a2(`
    <div class="element-custom">
      {{#let (element @component.tagName) as |Tag|}}
        <Tag props={{@component.props}} />
      {{/let}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
