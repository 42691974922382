import { template as template_e52bee022bc84f52b32be684b7ada831 } from "@ember/template-compiler";
import PixBackgroundHeader from '@1024pix/pix-ui/components/pix-background-header';
import PixBlock from '@1024pix/pix-ui/components/pix-block';
import CertificationStarter from '../certification-starter';
import CompanionBlocker from '../companion/blocker';
export default template_e52bee022bc84f52b32be684b7ada831(`
  <CompanionBlocker>
    <main class="main" role="main">
      <PixBackgroundHeader id="main">
        <PixBlock @shadow="heavy" class="certification-start-page__block">
          <CertificationStarter @certificationCandidateSubscription={{@certificationCandidateSubscription}} />
        </PixBlock>
      </PixBackgroundHeader>
    </main>
  </CompanionBlocker>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
