import { template as template_887a5d6fa6204aef9ceb1525076f9bdd } from "@ember/template-compiler";
export default template_887a5d6fa6204aef9ceb1525076f9bdd(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
