import { template as template_925216d519004211aa7c606a1f799cb8 } from "@ember/template-compiler";
import PixStars from '@1024pix/pix-ui/components/pix-stars';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import ShowMoreText from '../../../../../../components/show-more-text';
export default class EvaluationResultsDetailsTab extends Component {
    getIcon(competenceId) {
        return competencesIcons[competenceId] || competencesIcons.pixplus;
    }
    static{
        template_925216d519004211aa7c606a1f799cb8(`
    <tr class="evaluation-results-tab__competence">
      <td class="evaluation-results-tab-competence__image-cell">
        <img src={{this.getIcon @competence.id}} alt="" />
      </td>
      <td class="evaluation-results-tab-competence__content-cell">
        <h4 class="evaluation-results-tab-competence__name">{{@competence.name}}</h4>
        <ShowMoreText class="evaluation-results-tab-competence__description">
          {{@competence.description}}
        </ShowMoreText>
      </td>
      <td class="evaluation-results-tab-competence__results-cell">
        {{#if @total}}
          <PixStars
            class="evaluation-results-tab-competence-results__stars"
            @count={{@competence.acquiredStagesCount}}
            @total={{@total}}
            @alt={{t "pages.skill-review.stage.starsAcquired" acquired=@competence.acquiredStagesCount total=@total}}
            @color="yellow"
          />
        {{/if}}
        <p class="evaluation-results-tab-competence-results__mastery-percentage">
          {{t "pages.skill-review.stage.masteryPercentage" rate=@competence.masteryRate}}
        </p>
      </td>
    </tr>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
const ICON_DIR = '/images/icons/competences';
const competencesIcons = {
    pixplus: `${ICON_DIR}/pix-plus.svg`,
    recsvLz0W2ShyfD63: `${ICON_DIR}/mener-une-recherche.svg`,
    recIkYm646lrGvLNT: `${ICON_DIR}/gerer-des-donnees.svg`,
    recNv8qhaY887jQb2: `${ICON_DIR}/traiter-des-donnees.svg`,
    recDH19F7kKrfL3Ii: `${ICON_DIR}/interagir.svg`,
    recgxqQfz3BqEbtzh: `${ICON_DIR}/partager-et-publier.svg`,
    recMiZPNl7V1hyE1d: `${ICON_DIR}/collaborer.svg`,
    recFpYXCKcyhLI3Nu: `${ICON_DIR}/inserer-dans-le-monde-numerique.svg`,
    recOdC9UDVJbAXHAm: `${ICON_DIR}/developper-des-docs-textuels.svg`,
    recbDTF8KwupqkeZ6: `${ICON_DIR}/developper-des-docs-multimedia.svg`,
    recHmIWG6D0huq6Kx: `${ICON_DIR}/adapter-les-documents.svg`,
    rece6jYwH4WEw549z: `${ICON_DIR}/programmer.svg`,
    rec6rHqas39zvLZep: `${ICON_DIR}/securiser-environnement.svg`,
    recofJCxg0NqTqTdP: `${ICON_DIR}/proteger-les-donnees.svg`,
    recfr0ax8XrfvJ3ER: `${ICON_DIR}/proteger-la-sante.svg`,
    recIhdrmCuEmCDAzj: `${ICON_DIR}/problemes-techniques.svg`,
    recudHE5Omrr10qrx: `${ICON_DIR}/environnement-numerique.svg`
};
