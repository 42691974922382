import { template as template_3bb6881ef2e24e4b9d2db54559bd6a93 } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_3bb6881ef2e24e4b9d2db54559bd6a93(`
  <div class="element-text">
    {{htmlUnsafe @text.content}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
